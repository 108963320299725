import React from 'react';

import './farms.css'

import { Pen, Gap } from '../Pen/pen_v3';
import Info from '../Info/info_v3';

export default class Farms extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            seegraph: false,
            redirectedPen: undefined,
            data: []
        }
        this.changeState.bind(this);

        this.requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        }

    }

    componentDidMount(){
        fetch("http://noodles.run:45456/api/allpen",this.requestOptions)
        .then(response => response.json())
        .then(result => this.loadData(result.data))
    }

    loadData = function(res){
        
        this.setState({data: res})
        
    }

    

    changeState = (newState) => {
        this.setState({
            seegraph: newState.seegraph,
            redirectedPen: newState.redirectedPen 
        })
    }

    render() {
        if (!this.state.seegraph){
            return (
                <div className='grid-container'>
                    <Pen changeState={this.changeState} pen_id={70}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={50} data={this.state.data[19]}/>
                    <Pen changeState={this.changeState} pen_id={31} data={this.state.data[0]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={10}/>
                    <Pen changeState={this.changeState} pen_id={71}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={49} data={this.state.data[18]}/>
                    <Pen changeState={this.changeState} pen_id={32} data={this.state.data[1]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={9}/>
                    <Pen changeState={this.changeState} pen_id={72}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={48} data={this.state.data[17]}/>
                    <Pen changeState={this.changeState} pen_id={33} data={this.state.data[2]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={8}/>
                    <Pen changeState={this.changeState} pen_id={73}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={47} data={this.state.data[16]}/>
                    <Pen changeState={this.changeState} pen_id={34} data={this.state.data[3]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={7}/>
                    <Pen changeState={this.changeState} pen_id={74}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={46} data={this.state.data[15]}/>
                    <Pen changeState={this.changeState} pen_id={35} data={this.state.data[4]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={6}/>
                    <Pen changeState={this.changeState} pen_id={75}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={45} data={this.state.data[14]}/>
                    <Pen changeState={this.changeState} pen_id={36} data={this.state.data[5]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={5}/>
                    <Pen changeState={this.changeState} pen_id={76}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={44} data={this.state.data[13]}/>
                    <Pen changeState={this.changeState} pen_id={37} data={this.state.data[6]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={4} />
                    <Pen changeState={this.changeState} pen_id={77}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={43} data={this.state.data[12]}/>
                    <Pen changeState={this.changeState} pen_id={38} data={this.state.data[7]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={3}/>
                    <Pen changeState={this.changeState} pen_id={78}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={42} data={this.state.data[11]}/>
                    <Pen changeState={this.changeState} pen_id={39} data={this.state.data[8]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={2}/>
                    <Pen changeState={this.changeState} pen_id={79}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={41} data={this.state.data[10]}/>
                    <Pen changeState={this.changeState} pen_id={40} data={this.state.data[9]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={1}/>

                </div>
            )
        }
        else {
            return (
                <Info state={this.state} changeState={this.changeState}></Info>
            )
        }
    }
}
