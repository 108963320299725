import React from "react";
import 'chart.js/auto';
import {Line} from "react-chartjs-2";

import CloseIcon from '@mui/icons-material/Close';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import InventoryIcon from '@mui/icons-material/Inventory';
import ScaleIcon from '@mui/icons-material/Scale';
import SsidChartIcon from '@mui/icons-material/SsidChart';

import './info.css';

export default class Info extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        value: 0
      }
      this.chartReference = React.createRef();
      this.structure = {
        label: 'React',      
        data: {
          labels: [],
          datasets: [{
            label: "Inventory",
            data: [],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]       
        }

      };
      this.penid = props.state.redirectedPen;
      this.referencedData = {
        labels: [],
        inventory: [],
        av_weight: [],
        sd_weight: []
      }

      this.requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ penid: this.penid })
      }
    }

    componentDidMount(){
        fetch("http://noodles.run:45456/api/info",this.requestOptions)
        .then(response => response.json())
        .then(result => this.updateData(result.data))
    }

    updateData = function(res){
        res.forEach(element => {
            this.referencedData.labels.push(new Date(element.fecha).toLocaleDateString('en-US', {
                month: '2-digit',day: '2-digit',year: 'numeric'}))
            this.referencedData.inventory.push(element.inventory)
            this.referencedData.av_weight.push(element.av_weight)
            this.referencedData.sd_weight.push(element.sd_weight)
        });
        this.initiateChart()
        
    }

    initiateChart = function(){
        const ch = this.chartReference.current;
        ch.data.labels = this.referencedData.labels;
        ch.data.datasets[0].data = this.referencedData.inventory;
        ch.update();
    }

    updateChart = function(newValue) {
        
        const ch = this.chartReference.current;
        ch.data.labels = this.referencedData.labels;
        if (newValue === 0){
            ch.data.datasets[0].data = this.referencedData.inventory;
            ch.data.datasets[0].label = "Inventory";
        }
        if (newValue === 1){
            ch.data.datasets[0].data = this.referencedData.av_weight;
            ch.data.datasets[0].label = "Average weight";
        }
        if (newValue === 2){
            ch.data.datasets[0].data = this.referencedData.sd_weight;
            ch.data.datasets[0].label = "Dispersion of weight";
        }
        ch.update()
    }

    changeValue = function(newValue){
        this.setState({value: newValue});
        this.updateChart(newValue)
    }

    render() {
        return (
        <div className="info-container">
            <h1 className="info-header">Pen {this.props.state.redirectedPen} information</h1>
            <div className="info-buttoncontainer">
                <CloseIcon onClick={() => this.props.changeState({seegraph:false,redirectedPen:undefined})}></CloseIcon>
            </div>
            <div className="info-navigation">
                <BottomNavigation 
                    showLabels 
                    value={this.state.value} 
                    onChange={(event, newValue) => this.changeValue(newValue)}
                    sx={{ 
                        borderRadius: 5, 
                        backgroundColor: 'transparent', 
                        fontSize: 16,
                        transition: "2s",
                        '&:hover':{
                            backgroundColor: 'white'
                        }
                    }}
                >
                    <BottomNavigationAction label="Inventory" icon={<InventoryIcon/>}/>
                    <BottomNavigationAction label="Weight" icon={<ScaleIcon/>}/>
                    <BottomNavigationAction label="Dispersion" icon={<SsidChartIcon/>}/>
                </BottomNavigation>
            </div>
            {<Line ref={this.chartReference} options={{ maintainAspectRatio: true }} height={"80%"} data={this.structure.data}/>}
        </div>
    )}
}