import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import './App.css';

import Farms from '../Farms/farms_v2';
import Navbar from '../Navbar/navbar';
import Info from '../Info/info_v3';

function App(){
    return (
        <div className="wrapper">
            <Navbar />
            <BrowserRouter>
                <Routes>
                    <Route path="/farms" element={<Farms />} />
                    <Route path="/info" element={<Info></Info>} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App;